import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';

const Row = ({ data }) => (
  <>
    {data.map(cell => (
      <TableCell key={cell}>{cell}</TableCell>
    ))}
  </>
);

Row.propTypes = {
  data: PropTypes.array.isRequired
};

export { Row };
