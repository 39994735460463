import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  createButton: {
    position: 'absolute',
    right: '32px'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText
  }
}));
