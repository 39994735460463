import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import { useStyles } from './styled';

const SelectNumber = ({ helperText, onChange, numbers, value, error }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="number-simple">Number</InputLabel>
      <Select
        className={classes.inlineText}
        value={value}
        error={error}
        name="number"
        onChange={onChange}
        id="number-simple"
      >
        <MenuItem className={classes.borderBottom} value="">
          <em>Choose number</em>
        </MenuItem>
        {numbers.map(number => (
          <MenuItem
            className={classes.selectItems}
            value={number._id}
            key={number._id}
          >
            <div>
              {`${number.company.name} \n`}
              {`${number.number} \n`}
              {number.note}
            </div>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.errorText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

SelectNumber.defaultProps = {
  helperText: '',
  error: false
};

SelectNumber.propTypes = {
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      company: PropTypes.object.isRequired,
      createdDate: PropTypes.string.isRequired,
      note: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired
    })
  ).isRequired
};

export { SelectNumber };
