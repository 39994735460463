import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompaniesPage } from '../components';
import { companiesActions, dialogsActions, numbersActions } from '../../../redux/actions';

const CompaniesContainer = ({
  companies,
  fetchCompanies,
  deleteCompany,
  showModal,
  fetchNumbers,
  numbers
}) => {
  useEffect(() => {
    if (!companies.fetched) {
      fetchCompanies();
    }

    if (!numbers.fetched) {
      fetchNumbers()
    }

  }, [companies.fetched, fetchCompanies, numbers.fetched, fetchNumbers]);

  return (
    <CompaniesPage
      deleteCompany={deleteCompany}
      companies={companies}
      numbers={numbers.list}
      showModal={showModal}
    />
  );
};

CompaniesContainer.propTypes = {
  companies: PropTypes.shape({
    fetched: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired
  }).isRequired,
  numbers: PropTypes.shape({
    fetched: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired
  }),
  fetchCompanies: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const ConnectedCompaniesPage = connect(
  state => ({
    companies: state.companies,
    numbers: state.numbers
  }),
  {
    fetchCompanies: companiesActions.fetchCompanies,
    fetchNumbers: numbersActions.fetchNumbers,
    deleteCompany: companiesActions.deleteCompany,
    showModal: dialogsActions.showModal
  }
)(CompaniesContainer);
export { ConnectedCompaniesPage as CompaniesPage };
