import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonLink, PageContainer } from '../../components';
import { tasksActions } from '../../redux/actions';
import { TasksTable } from './Table';
import { useStyles } from './styled';

const TasksPage = ({ tasks, fetchTasks }) => {
  const classes = useStyles();
  useEffect(() => {
    fetchTasks();
    const timerId = setInterval(() => {
      fetchTasks();
    }, 60000);

    return () => {
      clearTimeout(timerId);
    };
  }, [fetchTasks]);

  return (
    <PageContainer pageName="Tasks">
      <ButtonLink styles={classes} path="/tasks/add">
        Add Task
      </ButtonLink>

      <TasksTable
        tasks={tasks.list}
      />
    </PageContainer>
  );
};

TasksPage.propTypes = {
  tasks: PropTypes.shape({
    list: PropTypes.array.isRequired,
    fetched: PropTypes.bool.isRequired
  }).isRequired,
  fetchTasks: PropTypes.func.isRequired
};

const ConnectedTaskPage = connect(
  state => ({
    tasks: state.tasks
  }),
  {
    fetchTasks: tasksActions.fetchTasks
  }
)(TasksPage);
export { ConnectedTaskPage as TasksPage };
