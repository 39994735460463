import { dialogsConstants } from '../types';

const showSpinner = () => ({ type: dialogsConstants.SHOW_SPINNER });
const hideSpinner = () => ({ type: dialogsConstants.HIDE_SPINNER });
const showModal = payload => ({ type: dialogsConstants.SHOW_MODAL, payload });
const hideModal = () => ({ type: dialogsConstants.HIDE_MODAL });

export const dialogsActions = {
  showSpinner,
  hideSpinner,
  showModal,
  hideModal
};
