import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Spinner, Modal } from '../../components';

const Dialogs = ({ showSpinner }) => {
  return ReactDOM.createPortal(
    <>
      {showSpinner && <Spinner />}

      <Modal />
    </>,
    document.getElementById('modal')
  );
};

export default connect(
  state => ({
    showSpinner: state.dialogs.showSpinner
  }),
  null
)(Dialogs);
