import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { SortIcon } from './SortIcon';

const NumbersTableHead = ({ setSortBy, setSortOrder, sortBy, sortOrder }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <SortIcon
            fieldName="company"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setOrder={setSortOrder}
            setBy={setSortBy}
          />
          {'Company name'}
        </TableCell>
        <TableCell>{'Number'}</TableCell>
        <TableCell>{'Note'}</TableCell>
        <TableCell>
          <SortIcon
            fieldName="createdDate"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setOrder={setSortOrder}
            setBy={setSortBy}
          />
          {'Created'}
        </TableCell>
        <TableCell>{'Action'}</TableCell>
      </TableRow>
    </TableHead>
  );
};

NumbersTableHead.propTypes = {
  setSortBy: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired
};

export { NumbersTableHead };
