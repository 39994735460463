import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ButtonLink, PageContainer } from '../../../components';
import { useStyles } from './styled';

const CompaniesPage = ({ companies, deleteCompany, showModal, numbers }) => {
  const classes = useStyles();

  const isDisabled = companyId => {
    const existingNumbers = numbers.find(num => num.company._id === companyId);
    return Boolean(existingNumbers);
  };

  const createModal = id =>
    showModal({
      title: 'Delete company',
      message: 'You will not be able to restore the company after deleting it.',
      action: () => deleteCompany(id),
      agreeTitle: 'Delete company',
      cancelTitle: 'Cancel'
    });

  return (
    <PageContainer pageName="Companies">
      <ButtonLink styles={classes} path="/companies/add">
        Add Company
      </ButtonLink>

      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Company name</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.list.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <i>No companies found</i>
                </TableCell>
              </TableRow>
            )}

            {companies.list.map(company => (
              <TableRow key={company._id}>
                <TableCell>{company.name}</TableCell>

                {/* actions (YOU CAN NOT DELETE A COMPANY, IF THERE'S A NUMBER WITH THIS COMPANY)*/}
                <TableCell align="center">
                  <IconButton
                    onClick={() => createModal(company._id)}
                    disabled={isDisabled(company._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </PageContainer>
  );
};

CompaniesPage.propTypes = {
  companies: PropTypes.shape({
    fetched: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired
    }).isRequired
  ),
  deleteCompany: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export { CompaniesPage };
