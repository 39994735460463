import { tasksConstants } from '../types';

const initialState = {
  list: [],
  fetched: false
};

export const tasks = (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case tasksConstants.FETCH_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: action.tasks
      };
    case tasksConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // CREATE
    case tasksConstants.CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.task]
      };
    case tasksConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // DELETE
    case tasksConstants.DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.id)
      };
    case tasksConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tasksConstants.CANCEL_TASK_SUCCESS:
      return {
        ...state,
        list: state.list.map(task => {
          if (task._id === action.payload.id) {
            return { ...task, status: 'canceled' };
          }
          return task;
        })
      };

    case tasksConstants.PAUSE_TASK_SUCCESS:
      return {
        ...state,
        list: state.list.map(task => {
          if (task._id === action.payload.id) {
            return { ...task, ...action.payload };
          }
          return task;
        })
      };

    case tasksConstants.RESUME_TASK_SUCCESS:
      return {
        ...state,
        list: state.list.map(task => {
          if (task._id === action.payload.id) {
            return {
              ...task,
              ...action.payload
            };
          }
          return task;
        })
      };

    case tasksConstants.PAUSE_TASK_FAILURE:
    case tasksConstants.RESUME_TASK_FAILURE:
    case tasksConstants.CANCEL_TASK_FAILURE:
      return {
        ...state,
        list: state.list.map(task => {
          if (task._id === action.error.id) {
            return { ...task, err: action.error };
          }
          return task;
        })
      };

    default:
      return state;
  }
};
