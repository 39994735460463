import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './redux';
import { App } from './app';
import axios from 'axios';

const bearerToken = store.getState().authentication.user ? `Bearer ${store.getState().authentication.user.token}` : '';
axios.defaults.headers.common['Authorization'] = bearerToken;
axios.defaults.baseURL = '/api';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
