import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import {
  numbersActions,
  dialogsActions,
  companiesActions
} from '../../redux/actions';
import { navigateAway, constructNavigateAway } from '../../helpers';
import { PageContainer } from '../../components';
import { NumberForm } from './Form';

const AddNumberPage = ({
  showModal,
  createNumber,
  fetchCompanies,
  companies
}) => {
  useEffect(() => {
    if (!companies.fetched) {
      fetchCompanies();
    }
  }, [companies.fetched, fetchCompanies]);

  const handleNavigateAway = values => {
    const modal = constructNavigateAway({
      state: values,
      item: 'number',
      path: '/numbers',
      showModal
    });
    navigateAway(modal);
  };

  return (
    <PageContainer pageName="Add number">
      <Paper>
        <NumberForm
          handleNavigateAway={handleNavigateAway}
          createNumber={createNumber}
          companies={companies.list}
        />
      </Paper>
    </PageContainer>
  );
};

AddNumberPage.propTypes = {
  showModal: PropTypes.func.isRequired,
  createNumber: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  companies: PropTypes.shape({
    list: PropTypes.array.isRequired,
    fetched: PropTypes.bool
  }).isRequired
};

const AddNumber = connect(
  state => ({
    companies: state.companies
  }),
  {
    createNumber: numbersActions.createNumber,
    showModal: dialogsActions.showModal,
    fetchCompanies: companiesActions.fetchCompanies
  }
)(AddNumberPage);

export { AddNumber };
