import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Pause as PauseIcon,
  PlayArrow as ResumeIcon,
  Cancel as CancelIcon,
  GetApp as DownloadIcon
} from '@material-ui/icons';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const closeMenuDecorator = (closeFn, fn) => () => {
  fn();
  closeFn();
};

export const ActionMenu = ({
  status,
  pauseTask,
  resumeTask,
  cancelTask,
  tryResumeTask,
  downloadCSV
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cancelTaskWrapped = closeMenuDecorator(handleClose, cancelTask);
  const resumeTaskWrapped = closeMenuDecorator(handleClose, resumeTask);
  const tryResumeTaskWrapped = closeMenuDecorator(handleClose, tryResumeTask);
  const pauseTaskWrapped = closeMenuDecorator(handleClose, pauseTask);
  const downloadCSVWrapped = closeMenuDecorator(handleClose, downloadCSV);

  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        ...
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status === 'active' && (
          <StyledMenuItem onClick={pauseTaskWrapped}>
            <ListItemIcon>
              <PauseIcon />
            </ListItemIcon>
            <ListItemText primary="Pause" />
          </StyledMenuItem>
        )}

        {status === 'paused' && (
          <StyledMenuItem onClick={resumeTaskWrapped}>
            <ListItemIcon>
              <ResumeIcon />
            </ListItemIcon>
            <ListItemText primary="Resume" />
          </StyledMenuItem>
        )}

        {status === 'paused' && (
          <StyledMenuItem onClick={cancelTaskWrapped}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <ListItemText primary="Cancel" />
          </StyledMenuItem>
        )}
        {status === 'failed' && (
          <StyledMenuItem onClick={tryResumeTaskWrapped}>
            <ListItemIcon>
              <ResumeIcon />
            </ListItemIcon>
            <ListItemText primary="Try resume" />
          </StyledMenuItem>
        )}
        {(status === 'failed' ||
          status === 'paused' ||
          status === 'finished' ||
          status === 'canceled') && (
          <StyledMenuItem onClick={downloadCSVWrapped}>
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Download CSV" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </div>
  );
};

ActionMenu.propTypes = {
  status: PropTypes.string.isRequired,
  pauseTask: PropTypes.func.isRequired,
  resumeTask: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  tryResumeTask: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired
};
