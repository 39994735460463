import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { companiesActions, dialogsActions } from '../../../redux/actions';
import { navigateAway, constructNavigateAway } from '../../../helpers';
import { AddCompanyPage } from '../components';

const AddCompanyContainer = ({ showModal, createCompany }) => {
  const handleNavigateAway = values => {
    const modal = constructNavigateAway({
      state: { ...values, server: '' },
      item: 'company',
      path: '/companies',
      showModal
    });
    navigateAway(modal);
  };

  return (
    <AddCompanyPage
      showModal={showModal}
      createCompany={createCompany}
      handleNavigateAway={handleNavigateAway}
    />
  );
};

AddCompanyContainer.propTypes = {
  showModal: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired
};

const AddCompanyContainerConnected = connect(
  null,
  {
    createCompany: companiesActions.createCompany,
    showModal: dialogsActions.showModal
  }
)(AddCompanyContainer);

export { AddCompanyContainerConnected as AddCompany };
