import React from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ActionMenu } from './ActionMenu';

const dateToHTML = d => {
  const [date, time] = new Date(d).toLocaleString().split(',');

  return (
    <>
      <span>{time.slice(0, -6).concat(time.slice(-3))}</span>
      <br />
      <span>{date}</span>
    </>
  );
};

const Body = ({ tasks }) => (
  <TableBody>
    {tasks.length === 0 && (
       <TableRow>
        <TableCell colSpan={7} align="center">
          <i>No tasks found</i>
        </TableCell>
       </TableRow>
    )}
    {tasks.map(
      ({
        taskName,
        number: { number, company, note },
        status,
        createdDate,
        progress,
        limit,
        finishedDate,
        _id,
        recipientsList: { list }
      }) => (
        <TableRow key={_id}>
          <TableCell>
            <span>{taskName}</span>
          </TableCell>

          <TableCell>
            <span>{number}</span>
            <br />
            <span>{company.name}</span>
            <br />
            <span>{note}</span>
          </TableCell>

          <TableCell>
            <span>{status}</span>
          </TableCell>

          <TableCell>
            <span>{dateToHTML(createdDate)}</span>
          </TableCell>

          <TableCell>
            <span>
              {progress}/{list.length}
            </span>
          </TableCell>

          {/* fake calculations */}
          <TableCell>
            <span>
              {dateToHTML(
                finishedDate !== null
                  ? finishedDate
                  : Date.now() + ((list.length - progress) / limit) * 60000
              )}
            </span>
          </TableCell>

          {/* actions */}
          <TableCell>
            <ActionMenu status={status} taskId={_id} />
          </TableCell>
        </TableRow>
      )
    )}
  </TableBody>
);

Body.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      taskName: PropTypes.string.isRequired,
      limit: PropTypes.number.isRequired,
      _id: PropTypes.string.isRequired,
      number: PropTypes.shape({
        company: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired,
        number: PropTypes.string.isRequired,
        note: PropTypes.string.isRequired
      }).isRequired,
      recipientsList: PropTypes.shape({
        list: PropTypes.array.isRequired
      })
    }).isRequired
  ).isRequired
};

export { Body as NumbersTableBody };
