export const numbersConstants = {
  FETCH_REQUEST: 'NUMBERS_FETCH_REQUEST',
  FETCH_SUCCESS: 'NUMBERS_FETCH_SUCCESS',
  FETCH_FAILURE: 'NUMBERS_FETCH_FAILURE',

  CREATE_REQUEST: 'NUMBERS_CREATE_REQUEST',
  CREATE_SUCCESS: 'NUMBERS_CREATE_SUCCESS',
  CREATE_FAILURE: 'NUMBERS_CREATE_FAILURE',

  DELETE_REQUEST: 'NUMBERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'NUMBERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'NUMBERS_DELETE_FAILURE'
};
