import React from 'react';
import { Typography, Container, Button } from '@material-ui/core';

const NotFound = ({ history }) => {
    const style = {
        margin: 12
    };

    return (
        <Container style={{
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center'
        }}>
            <Typography style={{...style, marginTop: 25}} variant="h2">Oops, 404 error</Typography>
            <Typography style={style} variant="h2">Not Found</Typography>
            <Button
              style={style}
              onClick={() => history.goBack()}
              color="primary"
              variant="outlined"
            >
                  Go back
            </Button>
        </Container>
    );
};


export { NotFound };