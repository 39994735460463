import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Dialog, DialogTitle } from '@material-ui/core';
import { FirstStep, SecondStep, ThirdStep } from './Steps';
import { recipientsActions } from '../../../../redux/actions';

const RecipientsModal = ({
  onRecipientsListCreated,
  createRecipientsList,
  recipientsList,
  closeModal
}) => {
  const [step, changeStep] = React.useState(1);

  let CurrentStep;
  switch (step) {
    case 1:
      CurrentStep = FirstStep;
      break;
    case 2:
      CurrentStep = SecondStep;
      break;
    case 3:
      CurrentStep = ThirdStep;
      break;
    default:
      CurrentStep = null;
  }

  return ReactDOM.createPortal(
    <Paper>
      <Dialog maxWidth="sm" open keepMounted fullWidth>
        <DialogTitle>Add recipients list</DialogTitle>

        <CurrentStep
          closeModal={closeModal}
          createRecipientsList={createRecipientsList}
          recipientsList={recipientsList}
          changeStep={changeStep}
          onRecipientsListCreated={onRecipientsListCreated}
        />
      </Dialog>
    </Paper>,
    document.getElementById('modal')
  );
};

RecipientsModal.propTypes = {
  onRecipientsListCreated: PropTypes.func.isRequired,
  createRecipientsList: PropTypes.func.isRequired,
  recipientsList: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    recipientsList: state.recipients.createdItem
  }),
  {
    createRecipientsList: recipientsActions.createRecipientsList
  }
)(RecipientsModal);
