import React from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button, TextField } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Recipients } from './Recipients';
import { useTaskReducer } from './hook.reducer';
import { SelectNumber } from './SelectNumber';
import { useStyles } from './styled';
import { getByteLen } from '../../../helpers';

const SMS_LEN = 160;

const TaskForm = ({ numbers, createTask, showModal, handleNavigationAway }) => {
  const [state, actions] = useTaskReducer();
  const classes = useStyles();

  return (
    <>
      <Recipients
        dispatchUploadingRecipientsFinish={actions.setUploadingRecipientsFinish}
        dipatchRecipientsListCreated={actions.setRecipientsCreated}
        displayModal={state.uploadingRecipients}
      />

      <Formik
        initialValues={{
          taskName: '',
          number: '',
          message: ''
        }}
        validationSchema={Yup.object({
          number: Yup.string().required('Required'),
          message: Yup.string().required('Required'),
          taskName: Yup.string()
            .min(2, 'Too short')
            .required('Required')
        })}
        onSubmit={values => {
          if (!state.createdRecipientsList) {
            showModal({
              title: 'Need recipient list',
              message: 'No recipients list found',
              action: actions.setUploadingRecipientsStart,
              agreeTitle: 'Upload',
              cancelTitle: 'Cancel'
            });
            return;
          }
          createTask({
            ...values,
            numberId: values.number,
            recipientsList: state.createdRecipientsList._id
          });
        }}
      >
        {({ errors, touched, values }) => (
          <Form className={classes.form}>
            <Field name="taskName">
              {({ field }) => (
                <TextField
                  {...field}
                  error={errors.taskName && touched.taskName}
                  helperText={touched.taskName && errors.taskName}
                  label="Task name"
                  type="text"
                  fullWidth
                  margin="normal"
                />
              )}
            </Field>

            <Field name="number">
              {({ field }) => (
                <SelectNumber
                  {...field}
                  error={errors.number && touched.number}
                  helperText={touched.number && errors.number}
                  numbers={numbers}
                  classes={classes}
                  value={values.number}
                  type="text"
                  fullWidth
                  margin="normal"
                />
              )}
            </Field>

            <Field name="message">
              {({ field, form }) => (
                <TextField
                  {...field}
                  error={errors.message && touched.message}
                  helperText={`${state.messageCharactersCount} chars${
                    state.messageCharactersCount > SMS_LEN
                      ? `(${Math.ceil(state.messageCharactersCount / SMS_LEN)} SMS)`
                      : ''
                  }`}
                  label="Message"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  margin="normal"
                  onChange={e => {
                    actions.setCharachersCount(getByteLen(e.target.value));
                    form.setFieldValue('message', e.target.value);
                  }}
                />
              )}
            </Field>

            <div className={classes.uploadButton}>
              <Button
                variant="contained"
                color="default"
                onClick={actions.setUploadingRecipientsStart}
              >
                Upload recipients list
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>

              {state.createdRecipientsList && (
                <div>{state.createdRecipientsList.name}</div>
              )}
            </div>

            <div className={classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleNavigationAway(values)}
              >
                Back
              </Button>

              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

TaskForm.propTypes = {
  numbers: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  handleNavigationAway: PropTypes.func.isRequired
};

export { TaskForm };
