import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles
} from '@material-ui/core';
import { dialogsActions } from '../../redux/actions';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const Modal = ({ showModal, closeModal, modal }) => {
  const classes = useStyles();
  if (!showModal) return <></>;

  const { title, message, action, agreeTitle, cancelTitle } = modal;
  const enhancedAction = () => {
    action();
    closeModal();
  };

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={closeModal} variant="contained">
          {cancelTitle}
        </Button>
        <Button onClick={enhancedAction} variant="contained" color="secondary">
          {agreeTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Modal.defaultProps = {
  modal: null,
  showModal: false
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  modal: PropTypes.object
};

const ConnectedModal = connect(
  state => ({
    showModal: state.dialogs.showModal,
    modal: state.dialogs.modal
  }),
  {
    closeModal: dialogsActions.hideModal
  }
)(Modal);
export { ConnectedModal as Modal };
