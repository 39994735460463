import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Paper } from '@material-ui/core';
import { NumbersTableHead } from './Head';
import { NumbersTableBody } from './Body';

const NumbersTable = ({ deleteNumber, showModal, classes, data, tasks }) => {
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const sorting = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder
  };
  
  const createModal = id => ({
    title: 'Delete number',
    message: 'You will not be able to restore the number after deleting it.',
    action: () => deleteNumber(id),
    agreeTitle: 'Delete number',
    cancelTitle: 'Cancel'
  });
  
  let sortedData;
  if (sortBy) {
    sortedData = data.sort((a, b) => {
      if (sortOrder === 'asc' && sortBy === 'company') {
        return a[sortBy].name > b[sortBy].name ? -1 : 1;
      } else if (sortOrder === 'asc' && sortBy === 'createdDate') {
        return new Date(a[sortBy]) - new Date(b[sortBy]);
      } else if (sortOrder === 'desc' && sortBy === 'company') {
        return a[sortBy].name < b[sortBy].name ? -1 : 1;
      } else if (sortOrder === 'desc' && sortBy === 'createdDate') {
        return new Date(b[sortBy]) - new Date(a[sortBy]);
      }
      return false;
    });
  } else {
    sortedData = data;
  }
  
  

  return (
    <Paper>
      <Table className={classes}>
        <NumbersTableHead {...sorting} />
        <NumbersTableBody
          data={sortedData}
          keyValue="_id"
          cells={['company', 'number', 'note', 'createdDate']}
          onDelete={id => showModal(createModal(id))}
          tasks={tasks}
        />
      </Table>
    </Paper>
  );
};

NumbersTable.propTypes = {
  deleteNumber: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  classes: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired
};

export { NumbersTable };
