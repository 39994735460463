import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const ButtonLink = ({ styles, path, children }) => (
  <Link className={styles.link} to={path}>
    <Button variant="contained" color="primary" className={styles.createButton}>
      {children}
    </Button>
  </Link>
);

ButtonLink.propTypes = {
  styles: PropTypes.shape({
    link: PropTypes.string.isRequired,
    createButton: PropTypes.string.isRequired
  }).isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};

export { ButtonLink };
