import { userConstants } from '../types';
import { history } from '../../helpers';
import { dialogsActions } from './dialogs';
import axios from 'axios';

const loginSuccess = user => ({ type: userConstants.LOGIN_SUCCESS, user });
const loginRequest = user => ({ type: userConstants.LOGIN_REQUEST, user });
const loginFailure = err => ({ type: userConstants.LOGIN_FAILURE, err });

const logout = () => {
  localStorage.removeItem('user');
  axios.defaults.headers.common['Authorization'] = '';
  history.push('/login');

  return {
    type: userConstants.LOGOUT
  };
};

const login = (username, password) => dispatch => {
  dispatch(loginRequest({ username }));
  dispatch(dialogsActions.showSpinner());
  const body = { email: username, password };

  axios
    .post(`/users/login`, body)
    .then(response => {
      // If status is not 200, throw response
      if (response.status !== 200) {
        // throw new Error(response);
        console.log(response);
        return;
      }

      // Get user, set it to local storage
      const user = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

      // Dispatch actions
      dispatch(loginSuccess(user));
      dispatch(dialogsActions.hideSpinner());

      // Redirect
      history.push('/');
    })
    .catch(error => {

      let errorMsg;
      if (error.response) {
        // if axios
        errorMsg = error.response.data.error ? error.response.data.error.message : error.response.data;
      } else {
        errorMsg = error.message;
      }
    
      dispatch(loginFailure(errorMsg));
      dispatch(dialogsActions.hideSpinner());
    });
};

export const userActions = {
  login,
  logout
};
