import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import jwtDecode from 'jwt-decode';
import { createLogger } from 'redux-logger';
import { rootReducer } from './reducers';
import { userActions } from './actions';
import { userConstants, dialogsConstants } from './types';

const customLogger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
});

const authNotRequiredActions = action =>
  action.type === userConstants.LOGIN_REQUEST ||
  action.type === userConstants.LOGIN_SUCCESS ||
  action.type === userConstants.LOGIN_FAILURE ||
  action.type === userConstants.LOGOUT ||
  action.type === dialogsConstants.HIDE_MODAL ||
  action.type === dialogsConstants.SHOW_MODAL ||
  action.type === dialogsConstants.HIDE_SPINNER ||
  action.type === dialogsConstants.SHOW_SPINNER;

const checkTokenExpirationMiddleware = store => next => action => {
  // If user the action does not require authorization
  if (authNotRequiredActions(action)) {
    return next(action);
  }

  try {
    const user = localStorage.getItem('user');
    const token = JSON.parse(user).token;
    const decodedToken = jwtDecode(token);

    // If the token has expired, logout
    if (decodedToken.exp < Date.now() / 1000) {
      return next(userActions.logout());
    } else {
      return next(action);
    }
  } catch (error) {
    // If token is invalid, logout
    console.log('checkTokenExpirationMiddleware', error);
    return next(userActions.logout());
  }
};

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, customLogger, checkTokenExpirationMiddleware)
);
