import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));
