import { dialogsConstants } from '../types';

const initialState = {
  showSpinner: false
};

export const dialogs = (state = initialState, action) => {
  switch (action.type) {
    case dialogsConstants.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true
      };
    case dialogsConstants.HIDE_SPINNER:
      return {
        ...state,
        showSpinner: false
      };
    case dialogsConstants.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        modal: {
          ...action.payload
        }
      };
    case dialogsConstants.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        modal: null
      };

    default:
      return state;
  }
};
