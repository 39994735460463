import { connect } from 'react-redux';
import { ActionMenu } from '../components';
import { tasksActions } from '../../../../../redux/actions';

const ConnectedActionMenu = connect(
  (state, ownProps) => ({
    status: ownProps.status
  }),
  (dispatch, ownProps) => ({
    pauseTask: () => tasksActions.pauseTask(ownProps.taskId)(dispatch),
    resumeTask: () => tasksActions.resumeTask(ownProps.taskId)(dispatch),
    cancelTask: () => tasksActions.cancelTask(ownProps.taskId)(dispatch),
    tryResumeTask: () => tasksActions.resumeTask(ownProps.taskId)(dispatch),
    downloadCSV: () => tasksActions.downloadCSV(ownProps.taskId)(dispatch)
  })
)(ActionMenu);

export { ConnectedActionMenu as ActionMenu };
