import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonLink, PageContainer } from '../../components';
import {
  numbersActions,
  dialogsActions,
  tasksActions
} from '../../redux/actions';
import { NumbersTable } from './Table';
import { useStyles } from './styled';

const Numbers = ({
  numbers,
  tasks,
  fetchTasks,
  fetchNumbers,
  deleteNumber,
  showModal
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!numbers.fetched) {
      fetchNumbers();
    }

    if (!tasks.fetched) {
      fetchTasks();
    }
  }, [numbers.fetched, fetchNumbers, tasks.fetched, fetchTasks]);

  return (
    <PageContainer pageName="Numbers">
      <ButtonLink styles={classes} path="/numbers/add">
        Add Number
      </ButtonLink>

      <NumbersTable
        classes={classes.table}
        data={numbers.list}
        showModal={showModal}
        deleteNumber={deleteNumber}
        tasks={tasks.list}
      />
    </PageContainer>
  );
};

Numbers.propTypes = {
  numbers: PropTypes.object.isRequired,
  fetchNumbers: PropTypes.func.isRequired,
  deleteNumber: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const ConnectedNumbersPage = connect(
  state => ({
    numbers: state.numbers,
    tasks: state.tasks
  }),
  {
    fetchNumbers: numbersActions.fetchNumbers,
    fetchTasks: tasksActions.fetchTasks,
    deleteNumber: numbersActions.deleteNumber,
    showModal: dialogsActions.showModal
  }
)(Numbers);
export { ConnectedNumbersPage as NumbersPage };
