import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import {
  PowerSettingsNew as LogoutIcon,
  Business as CompanyIcon,
  Phone,
  Assignment
} from '@material-ui/icons';
import { history } from '../../helpers';
import { userActions } from '../../redux/actions';
import CustomLink from './CustomLink';

const Header = ({ loggedIn, logout }) => {
  return loggedIn ? (
    <AppBar position="static">
      <Toolbar>
        <CustomLink color="inherit" to="/numbers" onClick={() => history.push('/numbers')}>
          <Phone style={{ marginRight: 5 }} />
          {' Numbers'}
        </CustomLink>

        <CustomLink color="inherit" to="/tasks" onClick={() => history.push('/tasks')}>
          <Assignment style={{ marginRight: 5 }} />
          {' Tasks'}
        </CustomLink>

        <CustomLink color="inherit" to="/companies" onClick={() => history.push('/companies')}>
          <CompanyIcon style={{ marginRight: 5 }} />
          {' Companies'}
        </CustomLink>

        {/* Empty element to take up space */}
        <Typography variant="h5" style={{ flex: 1 }} color="inherit" />

        <Button color="inherit" onClick={logout}>
          <LogoutIcon style={{ marginRight: 5 }} />
          {' Logout'}
        </Button>
      </Toolbar>
    </AppBar>
  ) : null;
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired
};

const mapActions = {
  logout: userActions.logout
};

const ConnectedHeader = connect(
  state => ({
    loggedIn: state.authentication.loggedIn
  }),
  mapActions
)(Header);

export { ConnectedHeader as Header };
