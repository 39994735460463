import React from 'react';
import PropTypes from 'prop-types';
import ImportRecipients from './ImportRecipientsList';

const Recipients = ({
  dispatchUploadingRecipientsFinish,
  dipatchRecipientsListCreated,
  displayModal
}) => (
  <>
    {displayModal && (
      <ImportRecipients
        onRecipientsListCreated={dipatchRecipientsListCreated}
        closeModal={dispatchUploadingRecipientsFinish}
      />
    )}
  </>
);

Recipients.propTypes = {
  dispatchUploadingRecipientsFinish: PropTypes.func.isRequired,
  dipatchRecipientsListCreated: PropTypes.func.isRequired,
  displayModal: PropTypes.bool.isRequired
};

export { Recipients };
