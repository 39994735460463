import { history } from './history';

export const constructNavigateAway = ({ path, item, state, showModal }) => ({
  state,
  path,
  showModal: action =>
    showModal({
      title: `Cancel ${item} creating`,
      message: 'You have unsaved data on this page that might be lost.',
      agreeTitle: 'Cancel creating',
      cancelTitle: 'Keep creating',
      action
    })
});

export const navigateAway = ({ state, path, showModal }) => {
  let shouldShowModal = false;
  Object.values(state).forEach(value => {
    if (value !== '') {
      shouldShowModal = true;
    }
  });

  if (shouldShowModal) {
    showModal(() => history.push(path));
  } else {
    history.push(path);
  }
};
