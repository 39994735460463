import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { Icon } from './IconSort';

const NumbersTableHead = ({ sortBy, sortOrder, setSortOrder, setSortBy }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Icon
            fieldName="taskName"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setOrder={setSortOrder}
            setBy={setSortBy}
          />
          {'Task name'}
        </TableCell>
        <TableCell>{'Send from'}</TableCell>
        <TableCell>{'Status'}</TableCell>
        <TableCell>
          <Icon
            fieldName="createdDate"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setOrder={setSortOrder}
            setBy={setSortBy}
          />
          {'Start date'}
        </TableCell>
        <TableCell>{'Progress'}</TableCell>
        <TableCell>{'End date'}</TableCell>
        <TableCell>{'Actions'}</TableCell>
        
      </TableRow>
    </TableHead>
  );
};

NumbersTableHead.propTypes = {
  setSortBy: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired
};


export { NumbersTableHead };
