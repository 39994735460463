import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { RecipientsDialog } from './RecipientsDialog';
import { useStyles } from './styled';

const ThirdStep = ({ onRecipientsListCreated, closeModal, recipientsList }) => {
  const classes = useStyles();
  const handleFinish = () => {
    onRecipientsListCreated({
      name: recipientsList.name,
      _id: recipientsList._id
    });
    closeModal();
  };

  return (
    <RecipientsDialog
      content={(
        <div>
          <Typography variant="subtitle1" className={classes.centered}>
            The list has been successfully added!
          </Typography>
        </div>
      )}
      actions={(
        <>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleFinish}
          >
            OK
          </Button>
        </>
      )}
      actionStyles={classes.thirdStepActions}
      step="3/3"
    />
  );
};

ThirdStep.propTypes = {
  onRecipientsListCreated: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  recipientsList: PropTypes.shape({
    list: PropTypes.array.isRequired,
    duplicates: PropTypes.array.isRequired,
    invalid: PropTypes.array.isRequired
  }).isRequired
};

export { ThirdStep };
