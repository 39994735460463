import { userConstants } from '../types';

// figure out if a user is logged in
const user =
  localStorage.getItem('user') !== 'undefined'
    ? JSON.parse(localStorage.getItem('user'))
    : null;
// set initial state
const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        user: action.user
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        error: action.err
      };

    case userConstants.LOGOUT:
      return {
        loggedIn: false
      };

    default:
      return state;
  }
};
