import React from 'react';
import { history } from '../helpers';
import Dialogs from '../pages/Dialogs';
import { PrivateRoute, NotLoggedInRoute } from '../components';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  Header,
  LoginPage,
  NumbersPage,
  AddNumber,
  TasksPage,
  AddTask,
  CompaniesPage,
  AddCompany,
  NotFound
} from '../pages';

export const App = () => {
  return (
    <Router history={history}>
      <Header />

      <Switch>
        <Route exact path="/" render={() => <Redirect to="/numbers" />} />

        <PrivateRoute exact path="/numbers" component={NumbersPage} />
        <PrivateRoute path="/numbers/add" component={AddNumber} />

        <PrivateRoute exact path="/tasks" component={TasksPage} />
        <PrivateRoute path="/tasks/add" component={AddTask} />

        <PrivateRoute exact path="/companies" component={CompaniesPage} />
        <PrivateRoute path="/companies/add" component={AddCompany} />

        <NotLoggedInRoute path="/login" component={LoginPage} />
        <Route component={NotFound} />
      </Switch>

      <Dialogs />
    </Router>
  );
};
