import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { FormHeader } from './FormHeader';
import { useStyles } from './styled';

const ControlledForm = ({ login, loginError }) => {
  const classes = useStyles();
  return (
    <>
      <FormHeader styles={classes} />
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email!')
            .required('Email required'),
          password: Yup.string().required('Password required')
        })}
        onSubmit={({ email, password }) => {
          login(email, password);
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form className={classes.form}>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <TextField
                    {...field}
                    error={errors.password && touched.password}
                    helperText={touched.password && errors.password}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                  />
                )}
              </Field>

              <div className={classes.errorMsg}>{loginError}</div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

ControlledForm.defaultProps = {
  loginError: ''
};

ControlledForm.propTypes = {
  login: PropTypes.func.isRequired,
  loginError: PropTypes.string
};

export { ControlledForm as LoginForm };
