import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { TaskForm } from './Form';
import { PageContainer } from '../../components';
import { navigateAway, constructNavigateAway } from '../../helpers';
import { numbersActions, tasksActions, dialogsActions } from '../../redux/actions';

const AddTaskPage = ({ showModal, fetchNumbers, createTask, numbers }) => {
  const handleNavigationAway = values => {
    const params = { state: values, item: 'task', path: '/tasks', showModal };
    const modal = constructNavigateAway(params);
    navigateAway(modal);
  };

  useEffect(() => {
    if (!numbers.fetched) {
      fetchNumbers();
    }
  }, [numbers.fetched, fetchNumbers]);

  return (
    <PageContainer pageName="Add task">
      <Paper>
        <TaskForm
          numbers={numbers.list}
          showModal={showModal}
          createTask={createTask}
          handleNavigationAway={handleNavigationAway}
        />
      </Paper>
    </PageContainer>
  );
};

AddTaskPage.propTypes = {
  fetchNumbers: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  numbers: PropTypes.shape({
    fetched: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired
  }).isRequired
};

const AddTask = connect(
  state => ({
    numbers: state.numbers
  }),
  {
    fetchNumbers: numbersActions.fetchNumbers,
    createTask: tasksActions.createTask,
    showModal: dialogsActions.showModal
  }
)(AddTaskPage);
export { AddTask };
