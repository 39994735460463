export const companiesConstants = {
  FETCH_REQUEST: 'COMPANIES_FETCH_REQUEST',
  FETCH_SUCCESS: 'COMPANIES_FETCH_SUCCESS',
  FETCH_FAILURE: 'COMPANIES_FETCH_FAILURE',

  CREATE_REQUEST: 'COMPANIES_CREATE_REQUEST',
  CREATE_SUCCESS: 'COMPANIES_CREATE_SUCCESS',
  CREATE_FAILURE: 'COMPANIES_CREATE_FAILURE',

  DELETE_REQUEST: 'COMPANIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANIES_DELETE_FAILURE'
};
