import { tasksConstants } from '../types';
import axios from 'axios';
import { history } from '../../helpers';
import { dialogsActions } from './dialogs';

// FETCH TASKS
const fetchRequest = () => ({ type: tasksConstants.FETCH_REQUEST });
const fetchSuccess = tasks => ({ type: tasksConstants.FETCH_SUCCESS, tasks });
const fetchFailure = error => ({ type: tasksConstants.FETCH_FAILURE, error });

const fetchTasks = () => dispatch => {
  dispatch(fetchRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .get('/tasks')
    .then(response => {
      dispatch(fetchSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      dispatch(fetchFailure(error));
      dispatch(dialogsActions.hideSpinner());
    });
};

// CREATE TASK
const createRequest = () => ({ type: tasksConstants.CREATE_REQUEST });
const createSuccess = task => ({ type: tasksConstants.CREATE_SUCCESS, task });
const createFailure = error => ({ type: tasksConstants.CREATE_FAILURE, error });

const createTask = data => dispatch => {
  dispatch(createRequest());

  axios
    .post('/tasks', data)
    .then(response => {
      dispatch(createSuccess(response.data));
      history.push('/tasks');
    })
    .catch(error => {
      dispatch(createFailure(error));
    });
};

// CANCEL TASK
const cancelRequest = () => ({ type: tasksConstants.CANCEL_TASK_REQUEST });
const cancelSuccess = payload => ({
  type: tasksConstants.CANCEL_TASK_SUCCESS,
  payload
});
const cancelFailure = error => ({
  type: tasksConstants.CANCEL_TASK_FAILURE,
  error
});

const cancelTask = id => dispatch => {
  dispatch(cancelRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .patch(`/tasks/${id}`, { status: 'canceled' })
    .then(response => {
      dispatch(cancelSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      dispatch(cancelFailure(error.response.data));
      dispatch(dialogsActions.hideSpinner());
    });
};

// PAUSE TASK
const pauseRequest = () => ({ type: tasksConstants.PAUSE_TASK_REQUEST });
const pauseSuccess = payload => ({
  type: tasksConstants.PAUSE_TASK_SUCCESS,
  payload
});
const pauseFailure = error => ({
  type: tasksConstants.PAUSE_TASK_FAILURE,
  error
});

const pauseTask = id => dispatch => {
  dispatch(pauseRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .patch(`/tasks/${id}`, { status: 'paused' })
    .then(response => {
      dispatch(pauseSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      dispatch(pauseFailure(error.response));
      dispatch(dialogsActions.hideSpinner());

      // Show modal 'try again'
      dispatch(
        dialogsActions.showModal({
          title: 'Error',
          message: error.response.data.message,
          action: () => dispatch(pauseTask(id)),
          agreeTitle: 'Try again',
          cancelTitle: 'Close'
        })
      );
    });
};

// RESUME TASK
const resumeRequest = () => ({ type: tasksConstants.RESUME_TASK_REQUEST });
const resumeSuccess = payload => ({
  type: tasksConstants.RESUME_TASK_SUCCESS,
  payload
});
const resumeFailure = error => ({
  type: tasksConstants.RESUME_TASK_FAILURE,
  error
});

const resumeTask = id => dispatch => {
  dispatch(resumeRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .patch(`/tasks/${id}`, { status: 'active' })
    .then(response => {
      dispatch(resumeSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      console.log(error.response);
      dispatch(resumeFailure(error.response));
      dispatch(dialogsActions.hideSpinner());

      // Show modal 'try again'
      dispatch(
        dialogsActions.showModal({
          title: 'Error',
          message: error.response.data.error
            ? error.response.data.error.message
            : error.response.data.message,
          action: () => dispatch(resumeTask(id)),
          agreeTitle: 'Try again',
          cancelTitle: 'Close'
        })
      );
    });
};

// DOWNLOAD CSV
const downloadCSVRequest = () => ({
  type: tasksConstants.DOWNLOAD_CSV_REQUEST
});
const downloadCSVSuccess = payload => ({
  type: tasksConstants.DOWNLOAD_CSV_SUCCESS,
  payload
});
const downloadCSVFailure = error => ({
  type: tasksConstants.DOWNLOAD_CSV_FAILURE,
  error
});

const downloadCSV = id => dispatch => {
  dispatch(downloadCSVRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .get(`/tasks/recipients/${id}`)
    .then(response => {
      // dispatch success
      dispatch(downloadCSVSuccess(response));
      dispatch(dialogsActions.hideSpinner());

      // create blob csv
      const pom = document.createElement('a');
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      });

      // download blob csv
      const url = URL.createObjectURL(blob);
      pom.href = url;
      const disposition = response.headers['content-disposition'];
      pom.setAttribute('download', disposition.slice(disposition.indexOf('name="') + 6, -1));
      pom.click();
    })
    .catch(error => {
      dispatch(downloadCSVFailure());
      dispatch(dialogsActions.hideSpinner());
    });
};

export const tasksActions = {
  fetchTasks,
  createTask,
  cancelTask,
  pauseTask,
  resumeTask,
  downloadCSV
};
