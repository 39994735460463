import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Paper } from '@material-ui/core';
import { NumbersTableHead } from './Head';
import { NumbersTableBody } from './Body';
import { useStyles } from './styled';

const TasksTable = ({ tasks }) => {
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const sorting = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder
  };

  let sortedData;
  if (sortBy) {
    sortedData = tasks.sort((a, b) => {
      if (sortOrder === 'asc' && sortBy === 'taskName') {
        return a[sortBy] > b[sortBy] ? -1 : 1;
      } else if (sortOrder === 'asc' && sortBy === 'createdDate') {
        return new Date(a[sortBy]) - new Date(b[sortBy]);
      } else if (sortOrder === 'desc' && sortBy === 'taskName') {
        return a[sortBy] < b[sortBy] ? -1 : 1;
      } else if (sortOrder === 'desc' && sortBy === 'createdDate') {
        return new Date(b[sortBy]) - new Date(a[sortBy]);
      }
      return false;
    });
  } else {
    sortedData = tasks;
  }

  const classes = useStyles();
  return (
    <Paper>
      <Table className={classes.table}>
        <NumbersTableHead {...sorting} />
        <NumbersTableBody tasks={sortedData} />
      </Table>
    </Paper>
  );
};

TasksTable.propTypes = {
  tasks: PropTypes.array.isRequired,
};

export { TasksTable };
