import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Paper, TextField, Button } from '@material-ui/core';
import { PageContainer } from '../../../components';
import { useStyles } from './styled';

const AddCompanyPage = ({ createCompany, handleNavigateAway }) => {
  const classes = useStyles();
  return (
    <PageContainer pageName="Add company">
      <Paper>
        <Formik
          initialValues={{
            name: '',
            appSecret: '',
            server: 'https://platform.devtest.ringcentral.com',
            appKey: ''
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, 'Too short')
              .required('Required'),
            appSecret: Yup.string()
              .min(10, 'Too short')
              .required('Required'),
            server: Yup.string()
              .url()
              .required('Required'),
            appKey: Yup.string()
              .min(10, 'Too short')
              .required('Required')
          })}
          onSubmit={values => createCompany(values)}
        >
          {({ errors, touched, values }) => {
            return (
              <Form className={classes.form}>
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      {...field}
                      error={errors.name && touched.name}
                      helperText={touched.name && errors.name}
                      label="Company name"
                      type="text"
                      fullWidth
                      margin="normal"
                    />
                  )}
                </Field>

                <Field name="appKey">
                  {({ field }) => (
                    <TextField
                      {...field}
                      error={errors.appKey && touched.appKey}
                      helperText={touched.appKey && errors.appKey}
                      label="App Key"
                      type="text"
                      fullWidth
                      margin="normal"
                    />
                  )}
                </Field>

                <Field name="appSecret">
                  {({ field }) => (
                    <TextField
                      {...field}
                      error={errors.appSecret && touched.appSecret}
                      helperText={touched.appSecret && errors.appSecret}
                      label="App Secret"
                      type="text"
                      fullWidth
                      margin="normal"
                    />
                  )}
                </Field>

                <Field name="server">
                  {({ field }) => (
                    <TextField
                      {...field}
                      error={errors.server && touched.server}
                      helperText={touched.server && errors.server}
                      label="Server"
                      type="text"
                      fullWidth
                      margin="normal"
                    />
                  )}
                </Field>

                <div className={classes.actions}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleNavigateAway(values)}
                  >
                    Back
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </PageContainer>
  );
};

AddCompanyPage.propTypes = {
  createCompany: PropTypes.func.isRequired,
  handleNavigateAway: PropTypes.func.isRequired
};

export { AddCompanyPage };
