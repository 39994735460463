/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { RecipientsDialog } from './RecipientsDialog';
import { useStyles } from './styled';

const SecondStep = ({ changeStep, recipientsList }) => {
  const classes = useStyles();

  let invalidText = null;
  if (
    recipientsList.duplicates.length > 0 &&
    recipientsList.invalid.length > 0
  ) {
    invalidText = `We ignored ${recipientsList.invalid.length} invalid and ${recipientsList.duplicates.length} duplicate records`;
  } else if (recipientsList.duplicates.length > 0) {
    invalidText = `We ignored ${recipientsList.duplicates.length} duplicate records`;
  } else if (recipientsList.invalid.length > 0) {
    invalidText = `We ignored ${recipientsList.invalid.length} invalid records`;
  }

  return (
    <>
      <RecipientsDialog
        content={
          <div>
            <Typography variant="h5" className={classes.centered}>
              Ready to import {recipientsList.list.length} good rows!
            </Typography>
            {invalidText && (
              <Typography variant="subtitle1" className={classes.centered}>
                {invalidText}
              </Typography>
            )}
          </div>
        }
        actions={
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => changeStep(1)}
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => changeStep(3)}
            >
              Import
            </Button>
          </>
        }
        actionStyles={classes.actions}
        step="2/3"
      />
    </>
  );
};

SecondStep.propTypes = {
  changeStep: PropTypes.func.isRequired,
  recipientsList: PropTypes.shape({
    list: PropTypes.array.isRequired,
    duplicates: PropTypes.array.isRequired,
    invalid: PropTypes.array.isRequired
  }).isRequired
};

export { SecondStep };
