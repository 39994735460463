export const tasksConstants = {
  FETCH_REQUEST: 'TASKS_FETCH_REQUEST',
  FETCH_SUCCESS: 'TASKS_FETCH_SUCCESS',
  FETCH_FAILURE: 'TASKS_FETCH_FAILURE',

  CREATE_REQUEST: 'TASKS_CREATE_REQUEST',
  CREATE_SUCCESS: 'TASKS_CREATE_SUCCESS',
  CREATE_FAILURE: 'TASKS_CREATE_FAILURE',

  CANCEL_TASK_REQUEST: 'CANCEL_TASK_REQUEST',
  CANCEL_TASK_SUCCESS: 'CANCEL_TASK_SUCCESS',
  CANCEL_TASK_FAILURE: 'CANCEL_TASK_FAILURE',

  PAUSE_TASK_REQUEST: 'PAUSE_TASK_REQUEST',
  PAUSE_TASK_SUCCESS: 'PAUSE_TASK_SUCCESS',
  PAUSE_TASK_FAILURE: 'PAUSE_TASK_FAILURE',

  RESUME_TASK_REQUEST: 'RESUME_TASK_REQUEST',
  RESUME_TASK_SUCCESS: 'RESUME_TASK_SUCCESS',
  RESUME_TASK_FAILURE: 'RESUME_TASK_FAILURE',

  DOWNLOAD_CSV_REQUEST: 'DOWNLOAD_CSV_REQUEST',
  DOWNLOAD_CSV_SUCCESS: 'DOWNLOAD_CSV_SUCCESS',
  DOWNLOAD_CSV_FAILURE: 'DOWNLOAD_CSV_FAILURE'
};
