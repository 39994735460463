import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CssBaseline, Container } from '@material-ui/core';
import { userActions } from '../../redux/actions';
import { LoginForm } from './Form';
import { useStyles } from './styled';

const LoginPage = ({ loggedIn, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {!loggedIn && (
        <Container component="main" maxWidth="xs">
          <CssBaseline>
            <div className={classes.paper}>
              <LoginForm {...props} />
            </div>
          </CssBaseline>
        </Container>
      )}
    </>
  );
};

LoginPage.defaultProps = {
  loggedIn: false
};

LoginPage.propTypes = {
  loggedIn: PropTypes.bool
};

const connectedLoginPage = connect(
  state => ({
    loggingIn: state.authentication.loggingIn,
    loginError: state.authentication.error
  }),
  {
    login: userActions.login
  }
)(LoginPage);
export { connectedLoginPage as LoginPage };
