import { authentication } from './authentication';
import { companies } from './companies';
import { recipients } from './recipients';
import { dialogs } from './dialogs';
import { numbers } from './numbers';
import { tasks } from './tasks';

import { userConstants } from '../types';

export const rootReducer = (state = [], action) => {

  // Clear store if user logs out
  if (action.type === userConstants.LOGOUT) {
    state = []
  }

  return {
    authentication: authentication(state.authentication, action),
    recipients: recipients(state.recipients, action),
    companies: companies(state.companies, action),
    dialogs: dialogs(state.dialogs, action),
    numbers: numbers(state.numbers, action),
    tasks: tasks(state.tasks, action)
  };
};