import { numbersConstants } from '../types';
import axios from 'axios';
import { history } from '../../helpers';
import { dialogsActions } from './dialogs';

// FETCH NUMBERS
const fetchRequest = () => ({ type: numbersConstants.FETCH_REQUEST });
const fetchSuccess = numbers => ({
  type: numbersConstants.FETCH_SUCCESS,
  numbers
});
const fetchFailure = error => ({ type: numbersConstants.FETCH_FAILURE, error });

const fetchNumbers = () => dispatch => {
  dispatch(fetchRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .get('/numbers')
    .then(response => {
      dispatch(fetchSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      dispatch(fetchFailure(error));
      dispatch(dialogsActions.hideSpinner());
    });
};

// CREATE NUMBER
const createRequest = () => ({ type: numbersConstants.CREATE_REQUEST });
const createSuccess = number => ({
  type: numbersConstants.CREATE_SUCCESS,
  number
});
const createFailure = error => ({
  type: numbersConstants.CREATE_FAILURE,
  error
});

const createNumber = data => dispatch => {
  dispatch(createRequest());

  axios
    .post('/numbers', data)
    .then(response => {
      dispatch(createSuccess(response.data));
      history.push('/numbers');
    })
    .catch(error => {
      dispatch(createFailure(error));

      // Show modal (most probably the number is not valid)
      dispatch(
        dialogsActions.showModal({
          title: 'Error',
          message: `${error.response.data.error.message}. Check the direct number, main line, extension and the app secret of the company`,
          action: () => history.push('/companies'),
          agreeTitle: 'Check company',
          cancelTitle: 'Close'
        })
      );
    });
};

// DELETE NUMBER
const deleteRequest = () => ({ type: numbersConstants.DELETE_REQUEST });
const deleteSuccess = id => ({ type: numbersConstants.DELETE_SUCCESS, id });
const deleteFailure = error => ({
  type: numbersConstants.DELETE_FAILURE,
  error
});

const deleteNumber = id => dispatch => {
  dispatch(deleteRequest());

  axios
    .delete(`/numbers/${id}`)
    .then(() => {
      dispatch(deleteSuccess(id));
    })
    .catch(error => {
      dispatch(deleteFailure(error));
    });
};

export const numbersActions = {
  fetchNumbers,
  createNumber,
  deleteNumber
};
