import React, { useEffect } from 'react';
import { Paper, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spinnerPaper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'rgba(2,2,2,.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const Spinner = () => {
  const classes = useStyles();
  
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowX = 'auto';
      document.body.style.overflowY = 'auto';
    };
  });

  return (
    <Paper className={classes.spinnerPaper}>
      <CircularProgress />
    </Paper>
  );
};
