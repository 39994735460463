import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  borderBottom: {
    borderBottom: '1px solid #d1d1d1'
  },
  selectItems: {
    whiteSpace: 'pre-line',
    borderBottom: '1px solid #d1d1d1'
  },
  inlineText: {
    whiteSpace: 'normal'
  },
  errorText: {
    color: 'red'
  }
});
