import { companiesConstants } from '../types';
import axios from 'axios';
import { history } from '../../helpers';
import { dialogsActions } from './dialogs';

// FETCH COMPANIES
const fetchRequest = () => ({ type: companiesConstants.FETCH_REQUEST });
const fetchSuccess = companies => ({
  type: companiesConstants.FETCH_SUCCESS,
  companies
});
const fetchFailure = error => ({
  type: companiesConstants.FETCH_FAILURE,
  error
});

const fetchCompanies = () => dispatch => {
  dispatch(fetchRequest());
  dispatch(dialogsActions.showSpinner());

  axios
    .get('/companies')
    .then(response => {
      dispatch(fetchSuccess(response.data));
      dispatch(dialogsActions.hideSpinner());
    })
    .catch(error => {
      dispatch(fetchFailure(error));
      dispatch(dialogsActions.hideSpinner());
    });
};

// CREATE COMPANY
const createRequest = () => ({ type: companiesConstants.CREATE_REQUEST });
const createSuccess = company => ({
  type: companiesConstants.CREATE_SUCCESS,
  company
});
const createFailure = error => ({
  type: companiesConstants.CREATE_FAILURE,
  error
});

const createCompany = data => dispatch => {
  dispatch(createRequest());

  axios
    .post('/companies', data)
    .then(response => {
      dispatch(createSuccess(response.data));
      history.push('/companies');
    })
    .catch(error => {
      dispatch(createFailure(error));
    });
};

// DELETE COMPANY
const deleteRequest = () => ({ type: companiesConstants.DELETE_REQUEST });
const deleteSuccess = id => ({ type: companiesConstants.DELETE_SUCCESS, id });
const deleteFailure = error => ({
  type: companiesConstants.DELETE_FAILURE,
  error
});

const deleteCompany = id => dispatch => {
  dispatch(deleteRequest());

  axios
    .delete(`/companies/${id}`)
    .then(() => {
      dispatch(deleteSuccess(id));
    })
    .catch(error => {
      dispatch(deleteFailure(error));
    });
};

export const companiesActions = {
  fetchCompanies,
  createCompany,
  deleteCompany
};
