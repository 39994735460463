import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@material-ui/core';

const FormHeader = ({ styles }) => (
  <>
    <Avatar className={styles.avatar}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Sign in
    </Typography>
  </>
);

FormHeader.propTypes = {
  styles: PropTypes.shape({
    avatar: PropTypes.string.isRequired
  }).isRequired
};

export { FormHeader };
