import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useStyles } from './styled';

const RecipientsDialog = ({ content, actions, step, actionStyles }) => {
  const classes = useStyles();
  return (
    <>
      <DialogContent>
        <div className={classes.step}>{step}</div>
        {content}
      </DialogContent>

      <DialogActions className={actionStyles}>{actions}</DialogActions>
    </>
  );
};

RecipientsDialog.propTypes = {
  content: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  step: PropTypes.string.isRequired,
  actionStyles: PropTypes.string.isRequired
};

export { RecipientsDialog };
