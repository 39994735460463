import { recipientsConstants } from '../types';

const initialState = {
  createdItem: {}
};

export const recipients = (state = initialState, action) => {
  switch (action.type) {
    // CREATE
    case recipientsConstants.CREATE_REQUEST:
      return state;
    case recipientsConstants.CREATE_SUCCESS:
      return {
        ...state,
        createdItem: action.recipientsList
      };
    case recipientsConstants.CREATE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
};
