import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { RecipientsDialog } from './RecipientsDialog';
import { useStyles } from './styled';

const FirstStep = ({ closeModal, changeStep, createRecipientsList }) => {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const handleContinue = () => {
    const fileObject = document.getElementById('upload-file-form').csv;
    if (fileObject.files.length === 0) {
      setError('Load the CSV file first');
      return;
    }
    const csv = fileObject.files[0];

    if (csv.name.slice(-3) !== 'csv') {
      setError('Required format: CSV');
      return;
    }
    const formData = new FormData();
    formData.append('recipientsList', csv);
    formData.append('name', csv.name);

    createRecipientsList(formData)
      .then(() => changeStep(2))
      .catch(err => console.log(err));
  };

  return (
    <RecipientsDialog
      content={(
        <form id="upload-file-form">
          <label htmlFor="upload-file">CSV file:</label>{' '}
          <input id="upload-file" type="file" name="csv" />
          {error && (
            <>
              <br />
              <span style={{ color: 'red' }}>{error}</span>
            </>
          )}
        </form>
      )}
      actions={(
        <>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </>
      )}
      actionStyles={classes.actions}
      step="1/3"
    />
  );
};

FirstStep.propTypes = {
  closeModal: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  createRecipientsList: PropTypes.func.isRequired
};

export { FirstStep };
