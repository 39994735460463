import { numbersConstants } from '../types';

const initialState = {
  list: [],
  fetched: false
};

export const numbers = (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case numbersConstants.FETCH_REQUEST:
      return state;
    case numbersConstants.FETCH_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: action.numbers
      };
    case numbersConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // CREATE
    case numbersConstants.CREATE_REQUEST:
      return state;
    case numbersConstants.CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.number]
      };
    case numbersConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // DELETE
    case numbersConstants.DELETE_REQUEST:
      return state;
    case numbersConstants.DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.id)
      };
    case numbersConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};
