import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button } from '@material-ui/core';
import MuiPhoneInput from 'material-ui-phone-number';
import { useStyles } from './styled';
import { SelectCompany } from './SelectCompany';


const numberRegExp = /^(\+?1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

const NumberForm = ({ createNumber, companies, handleNavigateAway }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        company: '',
        ext: '101',
        number: '',
        directNumber: '',
        password: '',
        note: ''
      }}
      validationSchema={Yup.object({
        company: Yup.string()
          .min(2, 'Too short')
          .required('Required'),
        ext: Yup.string()
          .min(3, 'Should be 3 or 4 digits long')
          .max(4, 'Should be 3 or 4 digits long')
          .required('Required'),
        number: Yup.string()
          .matches(numberRegExp, 'Should be a valid number')
          .required('Required'),
        directNumber: Yup.string()
          .matches(numberRegExp, 'Should be a valid number')
          .required('Required'),
        password: Yup.string().required('Required'),
        note: Yup.string().notRequired()
      })}
      onSubmit={values => createNumber(values)}
    >
      {({ errors, touched, values }) => {
        return (
          <Form className={classes.form}>
            <Field name="company">
              {({ field }) => (
                <SelectCompany
                  {...field}
                  error={errors.companyName && touched.companyName}
                  helperText={touched.companyName && errors.companyName}
                  label="Company"
                  type="text"
                  fullWidth
                  margin="normal"
                  companies={companies}
                />
              )}
            </Field>

            <div className={classes.numberSection}>
              <div className={classes.number}>
                <Field name="number">
                  {({ field, form }) => (
                    <MuiPhoneInput
                      {...field}
                      defaultCountry="us"
                      regions="america"
                      fullWidth
                      label="Number"
                      error={errors.number && touched.number}
                      helperText={touched.number && errors.number}
                      margin="normal"
                      onChange={value => form.setFieldValue('number', value)}
                    />
                  )}
                </Field>
              </div>
              
              <div className={classes.ext}>
                <Field name="ext">
                  {({ field }) => (
                    <TextField
                      {...field}
                      error={errors.ext && touched.ext}
                      helperText={touched.ext && errors.ext}
                      label="Ext"
                      type="number"
                      margin="normal"
                    />
                  )}
                </Field>
              </div>
            </div>


            <Field name="directNumber">
              {({ field, form }) => (
                <MuiPhoneInput
                  {...field}
                  defaultCountry="us"
                  regions="america"
                  fullWidth
                  label="Direct number"
                  error={errors.directNumber && touched.directNumber}
                  helperText={touched.directNumber && errors.directNumber}
                  margin="normal"
                  onChange={value => form.setFieldValue('directNumber', value)}
                />
              )}
            </Field>


            <Field name="password">
              {({ field }) => (
                <TextField
                  {...field}
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                  label="Password"
                  type="password"
                  fullWidth
                  margin="normal"
                />
              )}
            </Field>

            <Field name="note">
              {({ field }) => (
                <TextField
                  {...field}
                  error={errors.note && touched.note}
                  helperText={touched.note && errors.note}
                  label="Note"
                  type="text"
                  multiline
                  fullWidth
                  rows={3}
                  margin="normal"
                />
              )}
            </Field>

            <div className={classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleNavigateAway(values)}
              >
                Back
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

NumberForm.propTypes = {
  createNumber: PropTypes.func.isRequired,
  handleNavigateAway: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export { NumberForm };
