import axios from 'axios';
import { recipientsConstants } from '../types';
import { dialogsActions } from './dialogs';

// FORM_HEADER

const createRequest = () => ({ type: recipientsConstants.CREATE_REQUEST });
const createSuccess = recipientsList => ({
  type: recipientsConstants.CREATE_SUCCESS,
  recipientsList
});
const createFailure = error => ({
  type: recipientsConstants.CREATE_FAILURE,
  error
});

const createRecipientsList = data => dispatch => {
    dispatch(createRequest());
    dispatch(dialogsActions.showSpinner());

    return axios.post('/recipients', data).then(
      result => {
        dispatch(createSuccess(result.data));
        dispatch(dialogsActions.hideSpinner());
        return Promise.resolve();
      },
      error => {
        dispatch(createFailure(error));
        dispatch(dialogsActions.hideSpinner());
        return Promise.reject(error);
      }
    );
};

export const recipientsActions = {
  createRecipientsList
};
