import { companiesConstants } from '../types';

const initialState = {
  list: [],
  fetched: false
};

export const companies = (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case companiesConstants.FETCH_REQUEST:
      return state;
    case companiesConstants.FETCH_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: action.companies
      };
    case companiesConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // CREATE
    case companiesConstants.CREATE_REQUEST:
      return state;
    case companiesConstants.CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.company]
      };
    case companiesConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // DELETE
    case companiesConstants.DELETE_REQUEST:
      return state;
    case companiesConstants.DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.id)
      };
    case companiesConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};
