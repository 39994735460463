import React from 'react';

const constants = {
  SET_MESSAGE_CHARACTERS_COUNT: 'SET_MESSAGE_CHARACTERS_COUNT',
  RECIPIENTS_LIST_CREATED: 'RECIPIENTS_LIST_CREATED',
  START_UPLOADING_RECIPIENTS_LIST: 'START_UPLOADING_RECIPIENTS_LIST',
  FINISH_UPLOADING_RECIPIENTS_LIST: 'FINISH_UPLOADING_RECIPIENTS_LIST',
  FIELDS_CHANGE: 'FIELDS_CHANGE'
};

const initialState = {
  messageCharactersCount: 0,
  createdRecipientsList: null,
  uploadingRecipients: false
};

const createTaskReducer = (state, action) => {
  switch (action.type) {
    case constants.SET_MESSAGE_CHARACTERS_COUNT:
      return {
        ...state,
        messageCharactersCount: action.payload
      };
    case constants.RECIPIENTS_LIST_CREATED:
      return {
        ...state,
        createdRecipientsList: action.payload
      };
    case constants.START_UPLOADING_RECIPIENTS_LIST:
      return {
        ...state,
        uploadingRecipients: true
      };
    case constants.FINISH_UPLOADING_RECIPIENTS_LIST:
      return {
        ...state,
        uploadingRecipients: false
      };
    default:
      return state;
  }
};

export const useTaskReducer = () => {
  const [state, dispatch] = React.useReducer(createTaskReducer, initialState);

  const dispatchCharachersCount = count =>
    dispatch({ type: constants.SET_MESSAGE_CHARACTERS_COUNT, payload: count });
  const dipatchRecipientsListCreated = payload =>
    dispatch({ type: constants.RECIPIENTS_LIST_CREATED, payload });
  const dispatchUploadingRecipientsFinish = () =>
    dispatch({ type: constants.FINISH_UPLOADING_RECIPIENTS_LIST });
  const dispatchUploadingRecipientsStart = () =>
    dispatch({ type: constants.START_UPLOADING_RECIPIENTS_LIST });

  const actions = {
    setCharachersCount: dispatchCharachersCount,
    setRecipientsCreated: dipatchRecipientsListCreated,
    setUploadingRecipientsFinish: dispatchUploadingRecipientsFinish,
    setUploadingRecipientsStart: dispatchUploadingRecipientsStart
  };

  return [state, actions];
};
