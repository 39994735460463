import React from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableRow, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Row } from './Row';

const getNeededData = (item, cell) => {
  if (cell === 'createdDate') {
    return new Date(item[cell]).toDateString();
  }
  if (cell === 'company') {
    return item[cell].name;
  }
  return item[cell];
};

const NumbersTableBody = ({ data, cells, keyValue, onDelete, tasks }) => (
  <TableBody>
    {data.length === 0 && (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <i>No numbers found</i>
        </TableCell>
      </TableRow>
    )}
    {data.map(item => {
      const isDisabled = numberId => {
        const existingTasks = tasks.find(
          task => task.number._id === numberId
        );
        return Boolean(existingTasks);
      };

      return (
        <TableRow key={item[keyValue]}>
          <Row data={cells.map(cell => getNeededData(item, cell))} />

          {/* actions (YOU CAN NOT DELETE A PHONE, IF THERE'S A TASK WITH THIS PHONE) */}
          <TableCell>
            <IconButton
              onClick={() => onDelete(item[keyValue])}
              disabled={isDisabled(item._id)}
            >
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    })}
  </TableBody>
);

NumbersTableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  cells: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyValue: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.shape({
        _id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

export { NumbersTableBody };
