import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative'
  },
  heading: {
    marginTop: theme.spacing(8)
  }
}));

const PageContainer = ({ pageName, children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.heading} variant="h3">
        {pageName}
      </Typography>

      {children}
    </Container>
  );
};

PageContainer.propTypes = {
  pageName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export { PageContainer };
