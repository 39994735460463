import React from 'react';
import PropTypes from 'prop-types';

export const Icon = ({ fieldName, sortBy, sortOrder, setOrder, setBy }) => {
  const handleClick = order => {
    setOrder(order);
    setBy(fieldName);
  };

  const toggle = () => {
    const newOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    handleClick(newOrder);
  };

  return (
    <div onClick={toggle} style={{ display: 'inline-block', marginRight: 5 }}>
      <div
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          lineHeight: '8px',
          color: fieldName === sortBy && sortOrder === 'asc' ? 'black' : ''
        }}
      >
        &#9652;
      </div>
      <div
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          lineHeight: '8px',
          color: fieldName === sortBy && sortOrder === 'desc' ? 'black' : ''
        }}
      >
        &#9662;
      </div>
    </div>
  );
};

Icon.propTypes = {
  fieldName: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  setBy: PropTypes.func.isRequired
};
