import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';

const SelectCompany = ({ helperText, onChange, companies, value, error }) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="company-simple">Company</InputLabel>
      <Select
        value={value}
        error={error}
        name="company"
        onChange={onChange}
        id="company-simple"
      >
        <MenuItem value="">
          <em>Choose company</em>
        </MenuItem>
        {companies.map(company => (
          <MenuItem value={company._id} key={company._id}>
            <div>{company.name}</div>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SelectCompany.defaultProps = {
  helperText: '',
  error: false
};

SelectCompany.propTypes = {
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export { SelectCompany };
